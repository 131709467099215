import { useRegisterStyle } from '@didi/ec-base';

import * as chartToken from './token';

const useStyle = (prefixCls) => {

  return useRegisterStyle('pagelet-team-care-table', () => [
    {
      // base
      [`.${prefixCls}`]: {
        fontSize: '14px',
        [`.${prefixCls}-header`]: {
          display: 'flex',
          justifyContent: 'space-between',
          [`.${prefixCls}-header-item`]: {
            display: 'flex',
            alignItems: 'center',
            [`.${prefixCls}-scope`]: {
              marginRight: '16px',
              ['.ant5-select']: {
                ['.ant5-select-selector']: {
                  padding: '5px 6px',
                  borderRadius: '8px',
                  background: 'rgba(0,0,0,0.04)',
                  border: 'none',
                  ['.ant5-select-selection-item']: {
                    fontSize: '14px',
                    fontWeight: 400,
                    lineHeight: '22px',
                    letterSpacing: 0,
                    color: 'rgba(0, 0, 0, 0.84)',
                  }
                },
                ['.ant5-select-arrow']: {
                  right: '6px',
                  width: '14px',
                  height: '14px',
                  color: 'rgb(12, 21, 46)',
                  // top: '50%'
                },

                ['.ant5-select-dropdown']: {
                  width: 'auto !important',
                  padding: '4px',
                  borderRadius: '8px',
                  background: '#FFFFFF',
                  boxShadow: '0px 2px 15px 0px rgba(77, 108, 169, 0.17)',
                  ['.ant5-select-item']: {
                    minWidth: '100px !important',
                    fontSize: '14px',
                    lineHeight: '22px',
                    letterSpacing: 0,
                    color: 'rgba(0, 0, 0, 0.84)',
                    borderRadius: '4px',
                    ['&:hover']: {
                      background: 'rgba(0, 0, 0, 0.04)'
                    }
                  },
                  ['.ant5-select-item-option-selected']: {
                    color: '#FF6400',
                    background: '#FFF7F3',
                    ['&:hover']: {
                      background: '#FFF7F3'
                    }
                  }
                },
                ['&:hover ']: {
                  ['.ant5-select-selector']: {
                    background: '#F1F1F1',
                  },
                  ['.ant5-select-arrow']: {
                    opacity: '1',
                  },
                },
                ['&.ant5-select-focused .ant5-select-selector']: {
                  border: 'none',
                  boxShadow: 'none'
                }
              }

            },
            [`.${prefixCls}-time`]: {
              marginRight: '6px',
              borderRadius: '8px',
              background: 'rgba(0,0,0,0.04)',
              cursor: 'pointer',
              '.time-switch-popconfirm-content-item': {
                display: 'flex',
                padding: '5px 0px',
                position: 'relative',
                '.prev,.next': {
                  position: 'absolute',
                  top: '4px',
                  width: '20px',
                  height: '22px',
                  textAlign: 'center',
                  lineHeight: '22px',
                  zIndex: '999',
                },
                '.prev:hover, .next:hover': {
                  background: 'rgba(0, 0, 0, 0.08)',
                  borderRadius: '6px',
                  cursor: 'pointer'
                },
                '.prev': {
                  left: '4px'
                },
                '.next': {
                  right: '4px'
                },
                '.ant5-picker': {
                  background: 'none',
                  border: 'none',
                  padding: '0px',
                  '.ant5-picker-input input': {
                    textAlign: 'center',
                    width: '80px',
                    margin: '0 25px',
                    '&:hover': {
                      background: 'rgba(0, 0, 0, 0.08)',
                      borderRadius: '6px',
                      cursor: 'pointer'
                    }
                  }
                },
                '.ant5-picker-outlined:focus, .ant5-picker-outlined:focus-within': {
                  boxShadow: 'none',
                }
              }
            },
            ['.department-selection']: {
              ['.ehr-department-selection-new-style']: {
                ['.ant-select']: {
                  ['.ant-select-selector']: {
                    borderColor: '#f5f5f5',
                    background: '#f5f5f5'
                  }
                }
              }
            },
          }
        },

        [`.${prefixCls}-body`]: {
          marginTop: '20px',
          [`.${prefixCls}-body-title`]: {
            display: 'flex',
            justifyContent: 'space-between',
            [`.${prefixCls}-body-title-item`]: {
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              ['.title']: {
                marginRight: '8px',
                fontSize: '16px',
                fontWeight: 500,
                lineHeight: 'normal',
                letterSpacing: 0,
                color: 'rgba(0, 0, 0, 0.84)',
              },
              ['.switch-type']: {
                display: 'flex',
                justifyContent: 'space-between',
                // width: '172px',
                height: '32px',
                padding: '4px',
                borderRadius: '8px',
                background: 'rgba(0, 0, 0, 0.04)',
                ['.ant-btn']: {
                  height: '24px',
                  padding: '0px 12px',
                  color: 'rgba(0, 0, 0, 0.6)',
                  borderRadius: '4px',
                  borderColor: 'transparent',
                  background: 'transparent',
                  ['& > div']: {
                    display: 'none'
                  },
                  ['.ant-btn:hover, .ant-btn:focus']: {
                    color: 'rgba(0, 0, 0, 0.6)',
                    borderColor: 'transparent',
                    background: 'transparent',
                  },
                  ['&::after']: {
                    display: 'none',
                  },
                  ['&.active']: {
                    fontWeight: 500,
                    color: 'rgba(0, 0, 0, 0.84)',
                    borderColor: '#FFFFFF',
                    background: '#FFFFFF',
                    boxShadow: '0px 2px 6px 0px rgba(78, 98, 130, 0.02)',
                  }
                }
              },
              ['.export-btn']: {
                height: '32px',
                backgroundColor: '#FF6400',
                borderRadius: '4px',
                fontSize: '14px',
                color: '#FFFFFF',
                textAlign: 'center',
                lineHeight: '32px',
                cursor: 'pointer',
                padding: '0 15px',
                visibility: 'visible',

                ['.dot']: {
                  position: 'fixed',
                  transition: 'width .5s, height .5s, left .2s, top .2s, border-radius .5s, z-index .1s .2s',
                  width: '6px',
                  height: '6px',
                  borderRadius: '100%',
                  boxShadow: '0 0 0 1px #fff',
                }
              },
              ['.export-modal']: {
                ['.export-modal-content']: {
                  ['label']: {
                    fontSize: '14px',
                    color: '#333333',
                    marginBottom: '5px',
                    ['span']: {
                      fontSize: '15px',
                      color: '#FE4A68',
                    }
                  },
                  ['.tips']: {
                    fontSize: '12px',
                    color: '#AEAEAE',
                    padding: '4px 0px 0 12px',
                    marginBottom: 0,
                  }
                }
              }
            },
            [`.${prefixCls}-body-export-item`]: {
              ['.export-button']: {
                backgroundColor: '#FF7E41',
                borderRadius: '4px',
                fontSize: '12px',
                color: '#FFFFFF',
                lineHeight: '18px',
                textAlign: 'center',
                cursor: 'pointer',
                padding: '3px 10px',
                display: 'flex',
                verticalAlign: 'middle',
              }
            }
          },
          [`.${prefixCls}-body-content`]: {
            paddingTop: '12px',
            ['.ant-table-cell-content a']: {
              color: 'var(--gkc-primary-color)'
            },
            ['.ant5-table-expanded-row-fixed .ec-ehr-base-table-empty,.ec-ehr-base-table-empty']: {
              marginBlock: '96px',
              position: 'sticky',
              left: '0',
              textAlign: 'center',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              ['img']: {
                display: 'inline-block',
                width: '180px'
              },
              ['.ec-ehr-base-table-empty-text']: {
                fontSize: '12px',
                color: '#2D3040',
                letterSpacing: '0',
                lineHeight: '14px',
              }
            }
          }
        }
      },

      ['.ant-tooltip']: {
        '.ant-tooltip-arrow': {
          display: 'none'
        }
      }
    }
  ]);
};

export default useStyle;
