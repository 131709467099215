import React, { useEffect, useRef, useState } from 'react';
import * as antd from 'antd';
const { Button } = antd;
import { Intl, utils, newRequest } from '@didi/pagelets-sdk';
import { getLocale, ConfigProvider, Input, Spin, Modal, message, en_US, zh_CN, Segmented} from '@didi/ec-base';
import { DHRTable } from '@didi/ec-ehr-base';

import lodash from 'lodash';
import { emit, on } from './utils/event.js';
import manifest from '../manifest.json';
import PageletServices from './services';
import useStyle from './style';
import DepartmentSelection from "./components/DepartmentSelection/index.jsx";
import TimeFilter from "./components/TimeFilter/timeFileter.jsx";
import ScopeFilter from "./components/ScopeFilter/index.jsx";
import { CARE_CODE_ENUM, SORTER_CODE, COLUMNS, APIS, NAMES, NAMES_LIST, timeRangeTypeList } from "./constant.js";
import { TIME_RANGE_TYPE } from "./components/TimeFilter/constant.js";

import { FileImportOutlined } from '@didi/iconflow/ec';
import dayjs from 'dayjs';

const localLocales = manifest.locales || {};

const Pagelet = props => {
  const {
    params: {
      apiHost, locales, pageCode, permissionScope, defaultDeptCode, defaultDeptInfo, auth, headers = {}, onClickHiddenDrawer, startDate,
      endDate, timeRangeType, subordinate, subCode
    },
  } = props;
  const { functionId, functionName } = utils.getFunctionData(auth, 'care_summary');
  const newHeaders = {
    ...headers,
    'bricks-function-id': functionId,
    'Content-Type': 'application/json',
    functionName: window.encodeURI(functionName)
  }
  newRequest.init(newHeaders, 'care_summary');

  const prefixCls = 'pagelet-team-care-table';
  const wrapSSR = useStyle(prefixCls)
  // 多语言优先 props，再取本地
  const langLocales = locales || localLocales;
  const locale = getLocale(langLocales);
  const intl = Intl(locale);

  const { current: lang } = useRef(document.cookie.match(/lang=([a-zA-Z\-]*);/)?.[1] || 'zh-CN')
  const [providerLocale, setProviderLocale] = useState({});

  const [preQuery, setPreQuery] = useState(null);
  const [deptCode, setDeptCode] = useState([]);
  const [dimension, setDimension] = useState(timeRangeType);
  const [startTime, setStartTime] = useState(startDate);
  const [endTime, setEndTime] = useState(endDate);
  const [typeCode, setTypeCode] = useState(subCode);
  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [desc, setDesc] = useState({});
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);  //防止导出过程中取消
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [searchType, setsearchType] = useState(subordinate);
  const [clear, setClear] = useState(false);

  const changePage = (query, successCallback = () => { }) => {
    setLoading(true);
    const api = APIS[query.careType]
    PageletServices[api](apiHost, query).then((res) => {
      if (res.code === '100200' && res.data) {
        setLoading(false);
        successCallback(res.data)
      } else {
        setLoading(false);
      }
    }, (error) => {
      setLoading(false);
    });
  };

  const onDeptChange = (value, clear) => {
    setPageNum(1);
    setPageSize(10);
    if (value?.length === 0) {
      setDeptCode(defaultDeptCode);
    } else {
      setDeptCode(value);
    }
    if (clear) {
      const query = {
        deptCode: value?.length === 0 ? defaultDeptCode : value, startTime, endTime, typeCode, searchType, pageSize: 10, pageNum: 1
      }
      initData(query);
      setClear(true);
    } else {
      setClear(false);
    }
  };

  const onTimeChange = (time) => {
    setClear(false);
    setPageNum(1);
    setPageSize(10);
    setStartTime(dayjs(time[0]).valueOf());
    setEndTime(dayjs(time[1]).valueOf());
  };
  const onTimeRangeType = (v, item) => {
    setDimension(v);
    setClear(false);
    if (v === TIME_RANGE_TYPE.week) {
      setStartTime(dayjs().startOf('isoWeek').valueOf());
      setEndTime(dayjs().endOf('isoWeek').valueOf());
    } else if (v === TIME_RANGE_TYPE.month) {
      setStartTime(dayjs().startOf(TIME_RANGE_TYPE.month).valueOf());
      setEndTime(dayjs().endOf(TIME_RANGE_TYPE.month).valueOf());
    } else {
      setStartTime(dayjs().startOf(TIME_RANGE_TYPE.date).valueOf());
      setEndTime(dayjs().endOf(TIME_RANGE_TYPE.date).valueOf());
      }
  }

  useEffect(() => {
    setDimension(timeRangeType);
    setStartTime(startDate)
    setEndTime(endDate)
  }, [timeRangeType, startDate, endDate])

  const onScopeChange = (scope) => {
    setPageNum(1);
    setPageSize(10);
    setsearchType(scope);
    setClear(false);
  };

  const onChangeType = (value) => {
    if (typeCode !== value) {
      setPageNum(1);
      setPageSize(10);
      setTypeCode(value);
      setClear(false);
    }
  };

  const initData = (query) => {
    setList([]);
    const params = {
      "careType": typeCode,
      "deptCodes": query.deptCode,
      "export": 0,
      "pageNum": query?.pageNum,
      "pageSize": query?.pageSize,
      "pageType": pageCode,
      "endDate": query?.endTime,
      "searchType": query?.searchType,
      "startDate": query?.startTime,
    };
    setPreQuery(query);
    const successCallback = (data) => {
      const { list, ...desc } = data;
      setPageNum(params.pageNum)
      setDesc(desc || {});
      setList(list || []);
    }
    changePage(params, successCallback)
  };

  // 添加动画
  const addAnimation = () => {
    const X = document.querySelector(`.export-button`)
    const dot = document.querySelector('.ant-badge').getBoundingClientRect()
    const { x, y } = X.getBoundingClientRect()
    const newX = document.createElement('div', { class: 'export-button' });
    newX.style.backgroundColor = '#ED7343'
    newX.style.zIndex = 9999
    newX.style.position = 'fixed'
    newX.style.left = `${x}px`
    newX.style.top = `${y}px`
    X.appendChild(newX)
    setTimeout(() => {
      newX.className = 'dot'
      newX.style.left = `${dot.x + 75}px`
      newX.style.top = `${dot.y}px`
      newX.style.zIndex = -999
      newX.style.pointerEvents = 'none'
      newX.style.cursor = 'default'
    })
  }
  const onExport = () => {
    debugger
    let name = `${dayjs().format('YYYY_MM_DD')}导出员工${NAMES[typeCode]}名单`;
    Modal.confirm({
      icon: null,
      title: '导出文件',
      className: 'export-modal',
      getContainer: () => document.querySelector(`.${prefixCls}-body-title-item .export-btn`)?.parentNode,
      content: (
        <div className='export-modal-content'>
          <label><span>*</span> 文件命名: </label>
          <Input
            defaultValue={name}
            minLength={1}
            maxLength={40}
            placeholder='请输入长度40以内字符'
            onChange={
              (e) => {
                name = e.target.value;
              }
            }
          />
          <p className='tips'>确认后将生成文件，可至右上角“下载中心”查看和下载</p>
        </div>
      ),
      cancelButtonProps: { disabled: isDisabled },
      okButtonProps: { loading: confirmLoading },
      onOk: () => {
        if (name.length < 1) {
          message.warning('文件名不能为空');
          return Promise.reject();
        }
        setIsDisabled(true);
        setConfirmLoading(true);
        const params = {
          "careType": typeCode,
          "deptCodes": deptCode,
          "export": 1,
          "pageNum": 1,
          "pageSize": desc.total,
          "pageType": pageCode,
          "startDate": startTime,
          "endDate": endTime,
          "searchType": searchType,
          "fileName": name
        };
        const api = APIS[typeCode]
        return PageletServices[api](apiHost, params).then((res) => {
          if (res.code === '100200' && res.data) {
            message.success('请至下载中心获取导出的数据文件');
            emit('exportFile: success');
            addAnimation();
            setTimeout(() => {
              onClickHiddenDrawer();
            })
          } else {
            if (res.code === '100400') {
              message.warning(res.msg);
            }
          }
        }, (error) => {
        }).finally(() => {
          setConfirmLoading(false)
          setIsDisabled(false)
        });
      }
    })
  };


  const handleClick = () => {
    emit('exportFile: clicked');
    on('exportFile: exceed', () => {
      message.warning('其他文件正在导出中，请稍后重试');
    })
    onExport(true)
  };

  const onChangePageSize = ({ num, size, sorter }) => {
    const params = {
      "careType": typeCode,
      "deptCodes": deptCode,
      "export": 0,
      "pageNum": num,
      "pageSize": size,
      "pageType": pageCode,
      "endDate": endTime,
      "searchType": searchType,
      "startDate": startTime,
      ...sorter
    }
    setPreQuery(params)
    const successCallback = (data) => {
      const { list, ...desc } = data;
      setPageNum(params.pageNum);
      setPageSize(params.pageSize);
      setDesc(desc || {});
      setList(list || []);
    }
    changePage(params, successCallback)
  };

  useEffect(() => {
    if (lang !== 'zh-CN') {
      setProviderLocale(en_US)
    } else {
      setProviderLocale(zh_CN)
    }
  }, []);
  useEffect(() => {
    const query = {
      deptCode, startTime, endTime, typeCode, searchType, pageSize: 10, pageNum: 1
    }
    // console.log(query, !clear, dayjs(startTime).format('YYYY.MM.DD'), dayjs(endTime).format('YYYY.MM.DD'), '----query')
    if (deptCode?.length > 0 && startTime && endTime && typeCode && !clear && !loading) {
      initData(query)
    }
  }, [deptCode, searchType, startTime, endTime, typeCode]);



  return wrapSSR(
    <div className={prefixCls}>
      {/* 多语言使用 */}
      {/*<p>pagelet {intl('test')}</p>*/}
      {/*<p>变量多语言 {intl('hi, {username} !', { username: 'word' })}</p>*/}
      <ConfigProvider prefixCls="ant5" locale={providerLocale} autoInsertSpaceInButton={false}>
        <div className={`${prefixCls}-header`}>
          <div className={`${prefixCls}-header-item`}>
            <DepartmentSelection apiHost={apiHost} auth={auth} defaultDeptCode={defaultDeptCode} defaultDeptInfo={defaultDeptInfo} onDeptChange={onDeptChange} />
          </div>
          <div className={`${prefixCls}-header-item`}>
            <div className={`${prefixCls}-scope`}>
              {
                permissionScope?.length > 0 && <ScopeFilter subordinate={subordinate} list={permissionScope} onScopeChange={onScopeChange} />
              }
            </div>
            <div className={`${prefixCls}-scope`}>
              <ScopeFilter
                subordinate={dimension}
                list={timeRangeTypeList}
                onScopeChange={onTimeRangeType}
              />
            </div>
            <div className={`${prefixCls}-time`}>
              <TimeFilter
                source='team-care'
                dimension={dimension}
                startTime={startTime}
                endTime={endTime}
                lang={lang}
                locale={locale}
                onTimeChange={onTimeChange}
                onTimeRangeType={onTimeRangeType} />
            </div>
          </div>
        </div>
        <div className={`${prefixCls}-body`}>
          <div className={`${prefixCls}-body-title`}>
            <div className={`${prefixCls}-body-title-item`}>
              {/* <div className="title">关怀明细</div> */}
              <Segmented
                value={typeCode}
                options={NAMES_LIST}
                onChange={(value) => {
                  onChangeType(value); // string
                }}
              />
            </div>
            <div className={`${prefixCls}-body-export-item`}>
              <Button className="export-button" type={'primary'} onClick={handleClick}><FileImportOutlined /> 导出</Button>
            </div>
          </div>
          <div className={`${prefixCls}-body-content`}>
            <Spin spinning={loading}>
              <DHRTable
                locale={providerLocale}
                columns={COLUMNS[typeCode]}
                dataSource={list}
                scroll={{
                  x: 1080,
                  y: 'calc(100vh - 276px)', // 62 + 16 + 64 + 34 + 32 + 36 + 20 + 12
                }}
                onChange={({ current = 1, pageSize = 10 }, filters, sorter, { action }) => {
                  const { columnKey, order } = sorter;
                  const params = {
                    num: current,
                    size: pageSize
                  }
                  if (action === 'sort' && order) {
                    // SORTER_CODE:    birthdayDateSort   deptIdSort    hireDateSort
                    params.num = 1;
                    params.sorter = {
                      [SORTER_CODE[columnKey]]: order === 'descend' ? 'desc' : 'asc'
                    }
                  }
                  onChangePageSize(params)
                }}
                pagination={
                  {
                    current: pageNum,
                    pageSize: pageSize,
                    total: desc.total,
                    showQuickJumper: true,
                    showSizeChanger: true,
                  }
                }
              />
            </Spin>
          </div>
        </div>
      </ConfigProvider>
    </div>
  );
};

export default Pagelet;
